import React, { useEffect, useRef } from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import './NuestraEmpresa.css';

const directors = [
  {
    name: 'Juan Jara Parada',
    position: 'CEO Gestión y Marketing',
    description: 'Responsable de liderar la gestión y estrategias de marketing de la empresa.',
    image: './img/Ceo2.png',
  },
  {
    name: 'Juan Pozo Aravena',
    position: 'CEO Gestión y Tecnología',
    description: 'Encargado de dirigir las operaciones tecnológicas y la gestión de la tecnología.',
    image: './img/Ceo1.png',
  },
  {
    name: 'Benjamín Soto Villa',
    position: 'Desarrollador Web',
    description: 'Encargado de desarrollar y mantener la página web de la empresa.',
    image: './img/Benja-Soto.webp',
  },
  {
    name: 'Sebastian Troncoso',
    position: 'Creador de Contenido Audiovisual',
    description: 'Responsable de la creación de contenido audiovisual de la empresa.',
    image: './img/Seba-Troncoso.webp',
  }
];

const NuestraEmpresa = () => {
  const titleRef = useRef(null);
  const imageRef = useRef(null);
  const columnLeftRef = useRef(null);
  const columnRightRef = useRef(null);
  const visionRef = useRef(null);
  const missionRef = useRef(null);
  const carruselRef = useRef(null); // Nuevo ref para el scroll

  useEffect(() => {
    const handleScroll = () => {
      const elements = [
        columnLeftRef.current,
        columnRightRef.current,
        titleRef.current,
        imageRef.current,
        visionRef.current,
      ];
      elements.forEach((element) => {
        if (element) {
          const rect = element.getBoundingClientRect();
          if (rect.top < window.innerHeight && rect.bottom >= 0) {
            element.classList.add('animate-us');
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Función para animar el scroll con efecto slide al presionar las flechas
  const smoothScrollBy = (element, distance, duration) => {
    const start = element.scrollLeft;
    const startTime = performance.now();

    const animate = (currentTime) => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / duration, 1);
      // Efecto easeInOutQuad
      const ease =
        progress < 0.5
          ? 2 * progress * progress
          : -1 + (4 - 2 * progress) * progress;
      element.scrollLeft = start + distance * ease;
      if (progress < 1) {
        requestAnimationFrame(animate);
      }
    };
    requestAnimationFrame(animate);
  };

  const scrollLeft = () => {
    if (carruselRef.current) {
      smoothScrollBy(carruselRef.current, -300, 500);
    }
  };

  const scrollRight = () => {
    if (carruselRef.current) {
      smoothScrollBy(carruselRef.current, 300, 500);
    }
  };

  // Agregamos solo eventos touch para arrastrar en móviles
  useEffect(() => {
    const slider = carruselRef.current;
    let isDown = false;
    let startX;
    let scrollLeftStart;

    const touchStartHandler = (e) => {
      isDown = true;
      startX = e.touches[0].pageX - slider.offsetLeft;
      scrollLeftStart = slider.scrollLeft;
    };

    const touchEndHandler = () => {
      isDown = false;
    };

    const touchMoveHandler = (e) => {
      if (!isDown) return;
      const x = e.touches[0].pageX - slider.offsetLeft;
      const walk = (x - startX) * 2;
      slider.scrollLeft = scrollLeftStart - walk;
    };

    slider.addEventListener('touchstart', touchStartHandler);
    slider.addEventListener('touchend', touchEndHandler);
    slider.addEventListener('touchmove', touchMoveHandler);

    return () => {
      slider.removeEventListener('touchstart', touchStartHandler);
      slider.removeEventListener('touchend', touchEndHandler);
      slider.removeEventListener('touchmove', touchMoveHandler);
    };
  }, []);

  return (
    <div className="all-us">
      <Navbar />

      <section className="nuestra-empresa-us">
        <div className="columna-izquierda-us" ref={columnLeftRef}>
          <h1 className="titulo-grande-us" ref={titleRef}>Nuestra Empresa</h1>
          <p className="descripcion-us">
            Somos un equipo multidisciplinario que ofrece servicios de asesoría en <strong>Marketing Digital</strong> y venta de <strong>Productos Digitales</strong>.
            Te brindamos un acompañamiento en todos los pasos de tu negocio.
          </p>

          <div className="carrusel-container-us" ref={imageRef}>
            <button className="carrusel-btn-us prev-us" onClick={scrollLeft}>
              &#10094;
            </button>

            <div className="directores-carrusel-us" ref={carruselRef}>
              {directors.map((director, index) => (
                <div key={index} className="director-card-us">
                  <div className="director-icon-us">
                    <img src={director.image} alt={director.name} />
                  </div>
                  <h3>{director.name}</h3>
                  <h4>{director.position}</h4>
                </div>
              ))}
            </div>

            <button className="carrusel-btn-us next-us" onClick={scrollRight}>
              &#10095;
            </button>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default NuestraEmpresa;
