import React, { useState, useEffect, lazy, Suspense } from "react";

import "../Home/Home.css";
import "../Home/Home_Precios.css";
import "../Home/Home_Mision.css";
import "../Home/Home_Servicios.css";
import "../Home/Home_Categorias.css";

import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import data from "../Home/data.json";

// Lazy Loading de componentes
const HomePrecios = lazy(() => import("../Home/Home_Precios"));
const HomeMission = lazy(() => import("../Home/Home_Mision"));
const HomeServicios = lazy(() => import("../Home/Home_Servicios"));
const HomeCategorias = lazy(() => import("../Home/Home_Categorias"));

// Fallback para Suspense
const Loading = () => <div>Cargando...</div>;

const Home = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [prices, setPrices] = useState([]);
  const [missionText, setMissionText] = useState({});
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    // Cargar los datos desde el archivo JSON
    setPrices(data.prices || []);
    setMissionText(data.missionText || {});
    setCategories(data.categories || []);
  }, []);

  // Función que recibe el estado del menú desde Navbar
  const handleToggleMenu = (isOpen) => setMenuOpen(isOpen);

  return (
    <div>
      <header className="header">
        <Navbar onToggleMenu={handleToggleMenu} />
        <div className={`header-container ${menuOpen ? "menu-open" : ""}`}>
          <div className="header-content">
            <h1>Tu socio en la transformación digital.</h1>
            <p>
              Somos una empresa dedicada a ofrecer servicios de asesoría en
              marketing y venta de productos digitales.
            </p>
            <a href="/contacto" className="header-button">
              <button className="contact-button">Contáctanos</button>
            </a>
          </div>
          <div className="header-image-container">
            <img
              src="./img/Partners.webp"
              alt="Decorative"
              className="header-image"
            />
          </div>
        </div>
      </header>
      <div className="main-content">
        {/* Utilizamos Suspense con fallback mientras los componentes cargan */}
        <Suspense fallback={<Loading />}>
          <HomeMission
            missionText={missionText}
            firstImage={"./img/Post-3.webp"} // Primera imagen que no se agranda
            secondImages={[
              "./img/Post-2.webp",
              "./img/Post-8.webp",
              "./img/Post-7.webp",
              "./img/Post-6.webp",
            ]} // Imagenes que se muestran en el modal
          />
        </Suspense>

        <Suspense fallback={<Loading />}>
          <HomePrecios prices={prices} />
        </Suspense>

        <Suspense fallback={<Loading />}>
          <HomeServicios />
        </Suspense>

        <Suspense fallback={<Loading />}>
          <HomeCategorias categories={categories} />
        </Suspense>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
