import React from 'react';
import '../Productos/Products.css';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';

const Products = () => {
  const products = [
    {
      id: 1,
      name: 'Web Básica',
      description: 'Plan ideal para emprendedores y pequeñas empresas para mostrar su contenido.',
      price: '$181.000',
      image: './img/asesoriaweb.webp',
      link: 'https://api.whatsapp.com/send?phone=+56936619246&text=Hola!%20Quisiera%20una%20cotizaci%C3%B3n%20de%20*Creaci%C3%B3n%20y%20Desarrollo%20de%20P%C3%A1gina%20Web*%F0%9F%92%BB'
    },
    {
      id: 2,
      name: 'Web Standard',
      description: 'Desarrollamos tu sitio personalizado con sistema de reservas y gestión de tu sitio.',
      price: '$308.000',
      image: './img/web-standard.webp',
      link: 'https://api.whatsapp.com/send?phone=+56936619246&text=Hola!%20Quisiera%20una%20cotizaci%C3%B3n%20de%20*Creaci%C3%B3n%20y%20Desarrollo%20de%20P%C3%A1gina%20Web*%F0%9F%92%BB'
    },
    {
      id: 3,
      name: 'Web Catálogo',
      description: 'Diseñamos y desarrollamos tu sitio en donde puedes mostrar tus productos y servicios.',
      price: '$440.000',
      image: './img/contenidoredes.webp',
      link: 'https://api.whatsapp.com/send?phone=+56936619246&text=Hola!%20Quisiera%20una%20cotizaci%C3%B3n%20de%20*Creaci%C3%B3n%20y%20Desarrollo%20de%20P%C3%A1gina%20Web*%F0%9F%92%BB'
    },
    {
      id: 4,
      name: 'Web E-Commerce',
      description: 'Sitio exclusivo para ventas de productos y servicios digitales de tu marca.',
      price: '$585.000',
      image: './img/web-catalogo.webp',
      link: 'https://api.whatsapp.com/send?phone=+56936619246&text=Hola!%20Quisiera%20una%20cotizaci%C3%B3n%20de%20*Creaci%C3%B3n%20y%20Desarrollo%20de%20P%C3%A1gina%20Web*%F0%9F%92%BB'
    },
    {
      id: 5,
      name: 'Aplicaciones Móviles',
      description: 'Desarrollo de aplicaciones móviles para tu empresa.',
      price: 'Proximamente',
      image: './img/Aplicaciones.webp',
      link: 'https://digitalsolutionschile.cl/productos'
    },
    {
      id: 5,
      name: 'Chatbots y Asistentes Virtuales',
      description: 'Automatiza la atención al cliente de tu empresa.',
      price: 'Desde $100.000',
      image: './img/Chatbot.webp',
      link: 'https://api.whatsapp.com/send?phone=+56936619246&text=Hola!%20Quisiera%20una%20cotizaci%C3%B3n%20de%20*Chatbots%20y%20Asistentes%20Virtuales*https://api.whatsapp.com/send?phone=+56936619246&text=Hola!%20Quisiera%20una%20cotizaci%C3%B3n%20de%20*Chatbots%20y%20Asistentes%20Virtuales*%F0%9F%A4%96'
    },
    {
      id: 6,
      name: 'Desarrollo de Aplicaciones Web',
      description: 'Creamos herramientas para tu negocio en una versión web.',
      price: 'Próximamente',
      image: './img/HerramientasWeb.webp',
      link: 'https://digitalsolutionschile.cl/productos'
    },
    
  ];

  return (
    <div>
      <Navbar />
      <section className="product-section-products">
        <div className='container-title-products'>
          <h2 className="section-title-products">Nuestros Productos Digitales</h2>
        </div>
        <div className="product-list-products">
          {products.map((product) => (
            <div key={product.id} className="product-card-products">
              <div className="image-container-products">
                <img src={product.image} alt={product.name} className="product-image-products" />
              </div>
              <h3 className="product-name-products">{product.name}</h3>
              <p className="product-description-products">{product.description}</p>
              <span className="product-price-products">{/* {product.price} */}</span>
              <button
                className="buy-button-products"
                onClick={() => window.open(product.link, '_blank')} /* Abre el enlace en una nueva pestaña */
              >
                Solicita
              </button>
            </div>
          ))}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Products;
